import React from "react"

import MaestroRight from "./maestroRight"
import MaestroLeft from "./maestroLeft"
import { useStaticQuery, graphql } from "gatsby"

import "@brainhubeu/react-carousel/lib/style.css"
import { BrowserView, MobileView } from "react-device-detect"

export default function VisionAndMisionMain() {
  let data = useStaticQuery(graphql`
    query IndexMaestros {
      allAirtable(filter: { data: { section: { eq: "Maestros" }, data: {} } }) {
        nodes {
          table
          data {
            Name
            data
            data2
            data3
            img {
              thumbnails {
                full {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  data = data.allAirtable.nodes.filter(node => node.data.Name === "maestro")

  let maestros = []
  data.map(maestro => {
    maestros.push({
      img: maestro.data.img[0].thumbnails.full.url,
      name: maestro.data.data,
      position: maestro.data.data2,
      text: maestro.data.data3,
    })
    return null
  })

  return (
    <div>
      <BrowserView>
        {maestros.map((maestro, index) => {
          if (index % 2 === 0) {
            return <MaestroRight {...maestro} />
          } else {
            return <MaestroLeft {...maestro} />
          }
        })}
      </BrowserView>
      <MobileView>
        {maestros.map(maestro => {
          return <MaestroRight {...maestro} />
        })}
      </MobileView>
    </div>
  )
}
