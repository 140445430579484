import React from "react"
import maestroRighttStyles from "./maestroRight.module.scss"

export default function maestroRight(props) {
  return (
    <div className={maestroRighttStyles.container}>
      <div className={maestroRighttStyles.imgContainer}>
        <img
          className={maestroRighttStyles.img}
          src={props.img}
          alt="maestroImg"
        />
        <div className={maestroRighttStyles.textContainer}>
          <p className={maestroRighttStyles.name}>{props.name}</p>
          <p className={maestroRighttStyles.position}>{props.position}</p>
          <hr align="left" className={maestroRighttStyles.hr}></hr>
          <p className={maestroRighttStyles.text}>{props.text}</p>
        </div>
      </div>
    </div>
  )
}
