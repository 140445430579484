import React from "react"
import Layout from '../components/layout/layout'
import HeroWithTitle from "../components/maestros/heroWithImageMaestros"
import MaestrosMain from "../components/maestros/maestrosMain"
import "@brainhubeu/react-carousel/lib/style.css"
import HeroImage1 from "../images/heroMaestros.png"
import SEO from "../components/seo"

let heroWithTitle = {
  img: HeroImage1,
  title: "Maestros",
}

export default () => (
  <Layout>
    <SEO
      title="Teachers"
      description="Estos serán los profesores que te darán clases."
      // image={`https://www.ppdc.mx${blog}`}
      url="/maestros"
    />
    <HeroWithTitle {...heroWithTitle} />
    <MaestrosMain />
  </Layout>
)
